<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Blogs</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <b-carousel
        id="carousel-caption"
        controls
        indicators
      >
        <b-carousel-slide
          caption-html="<span class='text-white'>First Slide Label</span>"
          text="Donut jujubes I love topping I love sweet. Jujubes I love brownie gummi bears I love donut sweet chocolate. Tart chocolate marshmallow.Tart carrot cake muffin."
          :img-src="require('@/assets/images/slider/08.jpg')"
        />
        <b-carousel-slide
          caption-html="<span class='text-white'>Second Slide Label</span>"
          text="Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies. Cupcake cake I love cake danish carrot cake."
          :img-src="require('@/assets/images/slider/09.jpg')"
        />
        <b-carousel-slide
          caption-html="<span class='text-white'>Third Slide Label</span>"
          text="Pudding sweet pie gummies. Chocolate bar sweet tiramisu cheesecake chocolate cotton candy pastry muffin. Marshmallow cake powder icing."
          :img-src="require('@/assets/images/slider/10.jpg')"
        />
      </b-carousel>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BCarousel,
    BCarouselSlide,
  },
}
</script>

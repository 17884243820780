<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="mr-25 mb-0">
        Updated 1 month ago
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row class="match-height">
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            icon="EyeIcon"
            statistic="36.9k"
            statistic-title="Views"
            color="info"
          />
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="warning"
            icon="MessageSquareIcon"
            statistic="12k"
            statistic-title="Comments"
          />
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="danger"
            icon="ShoppingBagIcon"
            statistic="97.8k"
            statistic-title="Orders"
          />
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="HeartIcon"
            statistic="26.8"
            statistic-title="Favorited"
          />
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            color="success"
            icon="AwardIcon"
            statistic="689"
            statistic-title="Reviews"
          />
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            hide-chart
            color="danger"
            icon="TruckIcon"
            statistic="2.1k"
            statistic-title="Returns"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    StatisticCardVertical,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
    }
  },
}
</script>

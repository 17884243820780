<template>
  <section id="dashboard-homepage">
    <b-row class="match-height">
      <!-- Revenue Report Card -->
      <b-col lg="8">
        <homepage-statistics-group />
      </b-col>
      <!--/ Revenue Report Card -->

      <b-col lg="4">
        <homepage-goal-overview :data="data.goalOverview" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <homepage-analytic-revenue :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <homepage-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Developer Meetup Card -->
    </b-row>

    <b-row class="match-height">
      <b-col lg="8">
        <homepage-tips />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <homepage-blogs />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import HomepageGoalOverview from './HomepageGoalOverview.vue'
import HomepageTransactions from './HomepageTransactions.vue'
import HomepageStatisticsGroup from './HomepageStatisticsGroup.vue'
import HomepageAnalyticRevenue from './HomepageAnalyticRevenue.vue'
import HomepageTips from './HomepageTips.vue'
import HomepageBlogs from './HomepageBlogs.vue'

export default {
  components: {
    BRow,
    BCol,
    HomepageGoalOverview,
    HomepageTransactions,
    HomepageStatisticsGroup,
    HomepageAnalyticRevenue,
    HomepageTips,
    HomepageBlogs,

  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/homepage/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName || ''
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-homepage.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

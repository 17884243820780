<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Tips to boost your sales</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <app-timeline>
        <app-timeline-item
          title="Goal Achieved"
          subtitle="All milestones are completed"
          icon="AwardIcon"
          time="few minutes ago"
          variant="success"
        />

        <app-timeline-item
          title="Last milestone remain"
          subtitle="You are just one step away from your goal"
          icon="InfoIcon"
          time="3 minutes ago"
          variant="info"
        />

        <app-timeline-item
          title="Your are running low on time"
          subtitle="Only 30 minutes left to finish milestone"
          icon="ClockIcon"
          time="21 minutes ago"
          variant="warning"
        />

        <app-timeline-item
          title="Client Meeting"
          subtitle="New event has been added to your schedule"
          icon="UserIcon"
          time="36 minutes ago"
        />

        <app-timeline-item
          title="Product Design"
          subtitle="Product design added in workflow"
          icon="GridIcon"
          time="1 hour ago"
          variant="success"
        />
      </app-timeline>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,
  },
}
</script>
